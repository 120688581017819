$new-blue: #1d4567;
$new-yellow: #ffcc33;

$green-500: #22c55e;
$green-700: #15803d;
$lightBlue-500: #00c0eb;
$lightBlue-700: #0a7194;
$yellow-500: #ffc70e;
$yellow-700: #a37d00;
$blue-500: #3b82f6;
$blue-900: #1e3a8a;
$pink-500: #ec4899;
$pink-900: #831843;
$indigo-500: #6366f1;
$indigo-900: #312e81;
$teal-500: #14b8a6;
$teal-700: #0f766e;
$orange-500: #f97316;
$orange-700: #c2410c;
$shadow-color: rgba(0, 0, 0, 0.1);
.containerServices {
  width: 100%;
  padding: 1rem;
  margin: 3em auto;
  @media (min-width: 640px) {
    max-width: 640px;
    padding: 1.5rem;
  }
  @media (min-width: 768px) {
    max-width: 768px;
  }
  @media (min-width: 1024px) {
    max-width: 1024px;
  }
  @media (min-width: 1280px) {
    max-width: 1280px;
  }
  @media (min-width: 1536px) {
    max-width: 1536px;
  }
  .title {
    text-align: left;
    color: $new-blue;
    border-left: solid 6px $new-yellow;
    padding: 0.5em 1em;
    padding-bottom: 0;
    margin-left: 5em;
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.2em;
    @media screen and (max-width: 1200px) {
      margin-left: 2em;
    }
    @media screen and (max-width: 650px) {
      margin-left: 0em;
    }
  }
  .subtitle {
    text-align: left;
    color: $new-blue;
    padding: 0.5em 1em;
    margin-left: 5em;
    font-size: 17px;
    font-style: normal;
    line-height: 1.2em;
    @media screen and (max-width: 1200px) {
      margin-left: 2em;
    }
    @media screen and (max-width: 650px) {
      margin-left: 0em;
    }
  }
}

// Estilos para el contenedor de la grid
.grid-container {
  display: grid;
  grid-template-columns: 1fr; 
  gap: 2rem;
  margin-top: 2rem;
  overflow: visible;

  @media (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr); 
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr); 
  }
}


// Estilos para el contenedor de cada servicio
.service-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background-color: white;
  border-radius: 0.5rem;
  transition: box-shadow 1s, background-color 1s, opacity 1s;
  &:hover {
    &.from-green-500 {
      background: linear-gradient(to right, $green-500, $green-700);
    }

    &.from-lightBlue-500 {
      background: linear-gradient(to right, $lightBlue-500, $lightBlue-700);
    }

    &.from-yellow-500 {
      background: linear-gradient(to right, $yellow-500, $yellow-700);
    }

    &.from-blue-500 {
      background: linear-gradient(to right, $blue-500, $blue-900);
    }

    &.from-pink-500 {
      background: linear-gradient(to right, $pink-500, $pink-900);
    }

    &.from-indigo-500 {
      background: linear-gradient(to right, $indigo-500, $indigo-900);
    }

    &.from-teal-500 {
      background: linear-gradient(to right, $teal-500, $teal-700);
    }

    &.from-orange-500 {
      background: linear-gradient(to right, $orange-500, $orange-700);
    }
  }

  &.group {
    &:hover .service-title {
      color: white;
    }

    &:hover .service-description {
      color: #f9fafb; // light gray for contrast
    }
  }

  &.shadow-green-500 {
    box-shadow: 0 4px 6px $green-500;
    &:hover {
      box-shadow: 0 8px 15px $green-500;
    }
  }

  &.shadow-lightBlue-500 {
    box-shadow: 0 4px 6px $lightBlue-500;
    &:hover {
      box-shadow: 0 8px 15px $lightBlue-500;
    }
  }

  &.shadow-yellow-500 {
    box-shadow: 0 4px 6px $yellow-500;
    &:hover {
      box-shadow: 0 8px 15px $yellow-500;
    }
  }

  &.shadow-blue-500 {
    box-shadow: 0 4px 6px $blue-500;
    &:hover {
      box-shadow: 0 8px 15px $blue-500;
    }
  }

  &.shadow-pink-500 {
    box-shadow: 0 4px 6px $pink-500;
    &:hover {
      box-shadow: 0 8px 15px $pink-500;
    }
  }

  &.shadow-indigo-500 {
    box-shadow: 0 4px 6px $indigo-500;
    &:hover {
      box-shadow: 0 8px 15px $indigo-500;
    }
  }
  &.shadow-teal-500 {
    box-shadow: 0 4px 6px $teal-500;
    &:hover {
      box-shadow: 0 8px 15px $teal-500;
    }
  }

  &.shadow-orange-500 {
    box-shadow: 0 4px 6px $orange-500;
    &:hover {
      box-shadow: 0 8px 15px $orange-500;
    }
  }
}

// Estilos para los textos
.service-title {
  font-size: 1.25rem;
  font-weight: bold;
  transition: color 0.5;
  text-align: center;
}

.service-description {
  color: #888888;
  transition: color 0.5;
  text-align: center;
  font-size: 15px;
}

// Estilos para los íconos
.service-icon {
  width: 4rem;
  height: 4rem;
  &.green-500 {
    color: $green-500;
  }

  &.lightBlue-500 {
    color: $lightBlue-500;
  }

  &.yellow-500 {
    color: $yellow-500;
  }

  &.blue-500 {
    color: $blue-500;
  }

  &.pink-500 {
    color: $pink-500;
  }

  &.indigo-500 {
    color: $indigo-500;
  }

  &.teal-500 {
    color: $teal-500;
  }

  &.orange-500 {
    color: $orange-500;
  }
}

// Estilos adicionales
.service-content {
  margin: 0.6rem 0;
}
